import request from '@/utils/request'

//用户登录
export function apiLogin(data) {
    return request({
        url: '/re/sys/user/login',
        method: 'post',
        data: data
    });
}

//菜单栏
export function apiGetMenu(params) {
    return request({
        url: '/api/get/menu/list',
        method: 'get',
        params: params
    });
}


export function costimport(data) {
    return request({
        url: '/new/api/cost/import/overwrite',
        method: 'post',
        data: data
    });
}
