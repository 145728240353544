import axios from 'axios'
import store from '@/store'
import router from '@/router'
import Vue from "vue";
import {Message } from 'element-ui'


import { getToken, removeToken} from "@/utils/auth";

const service = axios.create({
	baseURL:process.env.VUE_APP_BASE_API, // api 的 base_url
   	timeout: 500000, // 请求超时
	// headers: {
	// 	"Content-Type": 'application/json;charset=utf-8'
	// }
})

service.interceptors.request.use(
	config => {
		let token = getToken();
		if (token) {
			config.headers.token = token
		}
    	return config
	},
	error => {
		Promise.reject(error)
	}
)

service.interceptors.response.use(
	response => {
		if (response.data.code == 200) {
			return response.data;
		} else if (response.data.code == 500) {
			return response.data;
		}else if (response.data.code == 401) {
			Message.error("登录状态已过期，请重新登录！")
			router.replace({
				path: "/login"
			});
			removeToken();
		}else {
			return Promise.reject(response.data)
		}
	},
	error => {
		console.log(error.response)
		return Promise.reject(error.response);
	}
)

export default service
