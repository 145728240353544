import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store/index";

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/base.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
Vue.use(ElementUI);

import Pagination from "@/components/Pagination";
Vue.component('Pagination', Pagination)

import Print from '@/plugs/print'
Vue.use(Print) // 注册


import '@/assets/css/base.less'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
