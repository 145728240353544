// 项目前缀，避免冲突

var storage = {
	// 设置存储
	set(key,value) {
		sessionStorage.setItem(key, value);
	},
	// 获取存储
	get(key) {
		return sessionStorage.getItem(key)
	},
	// 删除指定存储
	remove(key) {
		sessionStorage.removeItem(key);
	},
	// 删除所有存储
	clear() {
		sessionStorage.clear();
	},
	getl(key){
		return sessionStorage.getItem(key)
	}
}

export default storage;
