import Vue from 'vue'
import Vuex from 'vuex'
import {getInfo} from "@/api/base";
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    collapse: false,
    tags: [], // 标签栏数据
    menus:window.sessionStorage.getItem('menuList')?JSON.parse(window.sessionStorage.getItem('menuList')):[], // 菜单栏数据
    activeIndex:window.sessionStorage.getItem('activeIndex') ? window.sessionStorage.getItem('activeIndex') : '0',
    activeMenu:'',
  },
  mutations: {
    // setMenus(state, menus) {
    //   state.menus = menus;
    // },
    collapseMenu (state) {
      state.collapse=!state.collapse
    },
    // 添加标签
    ADD_TAG: (state, tag) => {
      if (!state.tags.some(item => item.path === tag.path)) {
        state.tags.push(tag)
      }
    },
    // 移除标签
    REMOVE_TAG: (state, tag) => {
      state.tags = state.tags.filter(item => item.path !== tag.path)
    },
    SET_TAGS: (state, tags) => {
      state.tags = tags
    },
    SET_MENUS: (state,menudata) => {
      state.menus = menudata;
      window.sessionStorage.setItem('menuList', JSON.stringify(state.menus))
    },
    SET_ACTIONINDEX:(state,activeIndex) =>{
      state.activeIndex = activeIndex;
      window.sessionStorage.setItem('activeIndex', activeIndex)

    },
    SETACTIONMENU:(state,activeMenu) =>{
      state.activeMenu = activeMenu;
      window.sessionStorage.setItem('activeMenu', activeMenu)

    },
  },
  //  actions :{
  //   fetchMenus({ commit }) {
  //     // 发送获取菜单栏数据的请求，从后台获取数据
  //     getInfo().then(res => {
  //       console.log("res1", res);
  //       commit("setMenus", res.data.menus); // 将菜单栏数据存到 Vuex 中
  //     });
  //   }
  // }


})
export default store