import storage from '@/assets/js/sessionStorage'

export function getToken() {
  return storage.get('Token')
}
export function getLToken(){
  return storage.getl('Token')
}
export function setToken(token) {
  storage.set('Token',token)
}

export function removeToken() {
  storage.remove('Token')
}
